import React, { useState, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../../../components/SEO/SEO"
import Layout from "../../../components/Layout"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"
import {
  Title,
  SubTitle,
  Button,
  Textarea,
  Field,
  Control,
  StaticButton,
  Select,
} from "../../../StyleComponents/styles"
import { FlexDiv } from "../../../StyleComponents/pagesStyle"

const navRoadArray = [
  { name: "Generator", link: "/generator/" },
  {
    name: "Straight Font Generator",
    link: "/straight-font-generator/",
  },
]
const seeAlsoArray = [
  "/font-generator",
  "/emoticons-copy-and-paste",
  "/emoji-copy-and-paste",
  "/text-art",
  "/text-emoticons-face-generator",
  "/html-entity",
]

function StraightFont(props) {
  const data = useStaticQuery(graphql`
    query {
      allFontListGeneratoeJson {
        edges {
          node {
            fontNames
            fontNum
            number {
              _0
              _1
              _2
              _3
              _4
              _5
              _6
              _7
              _8
              _9
            }
            alphabert {
              A
              B
              C
              D
              E
              F
              G
              H
              I
              J
              K
              L
              M
              N
              O
              P
              Q
              R
              S
              T
              U
              V
              W
              Y
              X
              Z
              a
              b
              c
              d
              e
              f
              g
              h
              i
              j
              k
              l
              m
              n
              o
              p
              q
              r
              s
              u
              t
              w
              v
              x
              y
              z
            }
          }
        }
      }
    }
  `)
  let textFontList = data.allFontListGeneratoeJson.edges[0].node
  let [textareaValue, settextareaValue] = useState("")
  let [resultTextarea, setresultTextarea] = useState("")
  let [textFont, settextFont] = useState(0)
  let [textNum, settextNum] = useState(0)
  let [copy, setcopy] = useState("Copy")
  let textRecultCopy = useRef("")
  function mycopy() {
    textRecultCopy.current.select()
    let copied = document.execCommand("Copy")
    if (copied) {
      setcopy("Copied :)")
      setTimeout(function () {
        setcopy("Copy")
      }, 1500)
    }
  }

  function changeNum(e) {
    settextNum(e.target.value)
  }

  function changeFont(e) {
    settextFont(e.target.value)
  }
  function textareaChange(e) {
    settextareaValue(e.target.value)
  }
  function generateTextarea() {
    let result = textareaValue.split("")
    let outputResult
    let inputValueArray = []
    result.forEach(x => {
      if (x.match(/\d+/g)) {
        inputValueArray.push(textFontList.number[`_${x}`][textNum])
      } else if (textFontList.alphabert[x] === undefined) {
        inputValueArray.push(x)
      } else {
        inputValueArray.push(textFontList.alphabert[x][textFont])
      }

      outputResult = inputValueArray.join("")
    })
    setresultTextarea(outputResult)
  }
  const generateRandomFont = () => {
    let result = textareaValue.split("")
    let outputResult
    let inputValueArray = []
    let randomTextFont = Math.floor(
      Math.random() * textFontList.fontNames.length
    )
    let randomNum = Math.floor(Math.random() * textFontList.fontNum.length)

    result.forEach(x => {
      if (x.match(/\d+/g)) {
        inputValueArray.push(textFontList.number[`_${x}`][randomNum])
      } else if (textFontList.alphabert[x] === undefined) {
        inputValueArray.push(x)
      } else {
        inputValueArray.push(textFontList.alphabert[x][randomTextFont])
      }

      outputResult = inputValueArray.join("")
    })
    setresultTextarea(outputResult)
  }
  return (
    <Layout location={props.location}>
      <SEO
        title="Straight Font Copy Paste"
        description="Straight Font Generator, generate regular fonts by selected the font you want, and copy-paste wherever you like. "
        keywords={[
          "Straight Font copy paste,regular font copy paste, regular text font copy paste, copy paste straight font.",
        ]}
      />

      <NavBar listPages={navRoadArray} />
      <section className="container">
        <Title>Straight Font Generator</Title>
        <SubTitle>Straight Text Font Generator Copy and Paste</SubTitle>
        <FlexDiv maxWidth="500px" className="mb-2">
          <Field>
            <Control>
              <StaticButton>Text Font</StaticButton>
            </Control>
            <Control className="expandedWidth">
              <Select>
                <select
                  className="staticSelect"
                  aria-label="TextFont"
                  onChange={changeFont}
                  onBlur={changeFont}
                  value={textFont}
                >
                  {textFontList.fontNames.map((name, index) => (
                    <option value={index} key={name + index}>
                      {name}
                    </option>
                  ))}
                </select>
              </Select>
            </Control>
          </Field>

          <Field>
            <Control>
              <StaticButton>Number</StaticButton>
            </Control>
            <Control className="expandedWidth">
              <Select>
                <select
                  className="staticSelect"
                  aria-label="num"
                  onChange={changeNum}
                  onBlur={changeNum}
                  value={textNum}
                >
                  {textFontList.fontNum.map((name, index) => (
                    <option value={index} key={"num" + index}>
                      {name}
                    </option>
                  ))}
                </select>
              </Select>
            </Control>
          </Field>
        </FlexDiv>
        <Textarea
          placeholder="Type or paste your text here :)"
          value={textareaValue}
          onChange={textareaChange}
        ></Textarea>
        <FlexDiv
          displayDiv="flex"
          flexWrap="wrap"
          justifyContent="center"
          className="mb-2"
        >
          <Button
            className="mb-2"
            borderColor="#1da1f2"
            color="#1da1f2"
            hoverColor="white"
            hoverBorderColor="#1da1f2"
            hoverBackgroundColor="#1da1f2"
            onClick={generateTextarea}
          >
            Make Straight Fonts
          </Button>

          <Button
            className="mb-2"
            type="button"
            borderColor="#1da1f2"
            color="white"
            backgroundColor="#1da1f2"
            hoverColor="#1da1f2"
            hoverBorderColor="#1da1f2"
            onClick={generateRandomFont}
          >
            Generate Random Fonts
          </Button>
        </FlexDiv>
        <Textarea
          placeholder="Result :)"
          value={resultTextarea}
          ref={textRecultCopy}
          readOnly
        ></Textarea>
        <div>
          <Button
            type="button"
            borderColor="#F55A34"
            color="#F55A34"
            hoverColor="white"
            hoverBorderColor="#F55A34"
            hoverBackgroundColor="#F55A34"
            onClick={mycopy}
          >
            {copy}
          </Button>
        </div>
        <br />
        <h3>Copy paste Straight Font</h3>
        <p>
          Input your text in the text area above then select the font you want,
          and click on the button "Make Fonts", your text will appear below or
          generate random straight font by clicking on the button "Generate
          Random Font", then you can copy and paste the text wherever you like.
        </p>
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />{" "}
      </section>
    </Layout>
  )
}

export default StraightFont
